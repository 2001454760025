import React, { FunctionComponent, Suspense, lazy } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "../styles/globalStyles.css";
import { AppChakraProvider } from "@tether-web-portal/components/global-styles/AppChakraProvider";
import { DialogProvider } from "@tether-web-portal/components/modals/hooks/DialogContext";
import { MetricsIntervalContextProvider } from "@tether-web-portal/contexts/MetricsIntervalContextProvider";
import HubSpotBox from "@tether-web-portal/components/HubSpotBot";
import { AuthProvider } from "../lib/auth/AuthProvider";
import { AuthGuardPage } from "./AuthGuardPage";
import { EmbeddedAuthGuard } from "../lib/auth/EmbeddedAuthGuard";
import { EmbeddedAuthProvider } from "../lib/auth/EmbeddedAuthProvider";
import AppLayout from "@tether-web-portal/components/AppLayout";
import { Loader } from "@tether-web-portal/components/loading/Loader";

interface LazyLoaderProps {
  children: any;
}
const LazyLoader: FunctionComponent<LazyLoaderProps> = ({ children }: LazyLoaderProps) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

const OrganisationSettingsPage = lazy(() => import("./OrganisationSettingsPage"));
const UserSettingsPage = lazy(() => import("./UserSettingsPage"));
const UserMembersPage = lazy(() => import("./UserMembersPage"));
const DeviceManagementPage = lazy(() => import("./DeviceManagementPage"));
const TetherTickPage = lazy(() => import("./TetherTickPage"));
const PropertiesPage = lazy(() => import("./PropertiesPage"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));
const PortfolioPage = lazy(() => import("./PortfolioPage"));
const AlertsPage = lazy(() => import("./AlertsPage"));
const OrganisationAirborneIndexPage = lazy(() => import("./airborne-index/OrganisationAirborneIndexPage"));
const RoomAirborneIndexPage = lazy(() => import("./airborne-index/RoomAirborneIndexPage"));
const LocationCircuitNodePage = lazy(() => import("./property/areas/LocationCircuitNodePage"));
const LocationDetailAlertsPage = lazy(() => import("./property/areas/LocationDetailAlertsPage"));
const LocationDetailPage = lazy(() => import("./property/areas/LocationDetailPage"));
const OccupancyPage = lazy(() => import("./property/OccupancyPage"));
const PropertyInitiativesPage = lazy(() => import("./property/PropertyInitiativesPage"));
const PropertyReportsPage = lazy(() => import("./property/reports/PropertyReportsPage"));
const PropertyOverviewPage = lazy(() => import("./property/PropertyOverviewPage"));
const PropertyTickPage = lazy(() => import("./property/PropertyTickPage"));
const PropertyDevicesPage = lazy(() => import("./property/PropertyDevicesPage"));
const PropertyAlertsPage = lazy(() => import("./property/PropertyAlertsPage"));
const PropertyLocationsPage = lazy(() => import("./property/areas/PropertyLocationsPage"));
const PropertyComparePage = lazy(() => import("./property/PropertyComparePage"));
const PropertyEditPage = lazy(() => import("./property/PropertyEditPage"));
const PropertyCreatePage = lazy(() => import("./property/PropertyCreatePage"));
const PropertyProjectPage = lazy(() => import("./property/projects/PropertyProjectPage"));
const PropertyNabersPage = lazy(() => import("./property/nabers/PropertyNabersPage"));
const PropertyNaberPage = lazy(() => import("./property/nabers/PropertyNaberPage"));
const PropertyInsightsPage = lazy(() => import("./property/insights/PropertyInsightsPage"));
const PropertyEditRoomPage = lazy(() => import("./property/areas/room/PropertyEditRoomPage"));
const PropertyCreateRoomPage = lazy(() => import("./property/areas/room/PropertyCreateRoomPage"));
const ExtPropertyOverviewPage = lazy(() => import("./ext/ExtPropertyOverviewPage"));
const ExtPropertyLocationPage = lazy(() => import("./ext/ExtPropertyLocationPage"));
const ExtAirborneIndexRoomPage = lazy(() => import("./ext/airborne-index/ExtAirborneIndexRoomPage"));
const ExtAirborneIndexOrganisationPage = lazy(() =>
  import("./ext/airborne-index/ExtAirborneIndexOrganisationPage")
);
const ExtAirborneIndexRedirect = lazy(() => import("./ext/airborne-index/ExtAirborneIndexRedirect"));
const CompanySettingsPage = lazy(() => import("./CompanySettingsPage"));
const CustomerOrderPage = lazy(() => import("./CustomerOrderPage"));
const PropertyManagementReportPage = lazy(() => import("./property/reports/PropertyManagementReportPage"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const isEmbedded = import.meta.env.VITE_PLATFORM_TYPE === "embedded";

  const routes = (
    <Routes>
      <Route
        path="/"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertiesPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route path="/auth/*" element={<AuthGuardPage />} />
      <Route
        path="/dashboard"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PortfolioPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/settings"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <OrganisationSettingsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/user-settings"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <UserSettingsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/company-settings"
        element={
          <LazyLoader>
            <CompanySettingsPage />
          </LazyLoader>
        }
      />
      <Route
        path="/customer-order"
        element={
          <LazyLoader>
            <CustomerOrderPage />
          </LazyLoader>
        }
      />
      <Route
        path="/members"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <UserMembersPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/device-management"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <DeviceManagementPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/tether-tick"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <TetherTickPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/alerts"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <AlertsPage />
            </LazyLoader>
          </AppLayout>
        }
      />

      <Route
        path="/area/circuitnode/:id"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <LocationCircuitNodePage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/area/:areaType/:id"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <LocationDetailPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/area/room/:id/edit"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyEditRoomPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/area/:areaType/:id/alerts"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <LocationDetailAlertsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/room/create"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyCreateRoomPage />
            </LazyLoader>
          </AppLayout>
        }
      />

      <Route
        path="/property/:id"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyOverviewPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/areas"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyLocationsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/occupancy"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <OccupancyPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/initiatives"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyInitiativesPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/reports"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyReportsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/tethertick"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyTickPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/devices"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyDevicesPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/alerts"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyAlertsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/insights"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyInsightsPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/compare"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyComparePage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/nabers"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyNabersPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/nabers/:nabersId"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyNaberPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/edit"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyEditPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/create"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyCreatePage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/property/:id/projects/:projectId"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <PropertyProjectPage />
            </LazyLoader>
          </AppLayout>
        }
      />

      <Route
        path="/airborne-index/organisation"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <OrganisationAirborneIndexPage />
            </LazyLoader>
          </AppLayout>
        }
      />
      <Route
        path="/airborne-index/room/:id"
        element={
          <AppLayout sideBar={!isEmbedded}>
            <LazyLoader>
              <RoomAirborneIndexPage />
            </LazyLoader>
          </AppLayout>
        }
      />

      <Route
        path="/reports/management"
        element={
          <LazyLoader>
            <PropertyManagementReportPage />
          </LazyLoader>
        }
      />

      <Route path="/ext">
        <Route
          path="property/:id"
          element={
            <LazyLoader>
              <ExtPropertyOverviewPage />
            </LazyLoader>
          }
        />
        <Route
          path="area/:areaType/:id"
          element={
            <LazyLoader>
              <ExtPropertyLocationPage />
            </LazyLoader>
          }
        />
        <Route
          path="airborne-index/room/:id"
          element={
            <LazyLoader>
              <ExtAirborneIndexRoomPage />
            </LazyLoader>
          }
        />
        <Route
          path="airborne-index/:id"
          element={
            <LazyLoader>
              <ExtAirborneIndexOrganisationPage />
            </LazyLoader>
          }
        />
        <Route
          path="airborne-index"
          element={
            <LazyLoader>
              <ExtAirborneIndexRedirect />
            </LazyLoader>
          }
        />
      </Route>

      <Route path="/ext/covid-care/*" element={<Navigate to="/ext/airborne-index/" replace />} />
      <Route path="/covid-care/*" element={<Navigate to="/airborne-index/" replace />} />
      <Route path="/main/installation/*" element={<Navigate to="/property/:path*" replace />} />
      <Route path="/main/manage-users" element={<Navigate to="/members" replace />} />
      <Route path="/main/healthy-home-standard" element={<Navigate to="/tether-tick" replace />} />
      <Route path="/main/store" element={<Navigate to="/tether-tick" replace />} />
      <Route path="/main/settings" element={<Navigate to="/settings" replace />} />
      <Route path="/main/manage-installations" element={<Navigate to="/" replace />} />

      <Route
        path="*"
        element={
          <LazyLoader>
            <NotFoundPage />
          </LazyLoader>
        }
      />
    </Routes>
  );

  if (isEmbedded) {
    return (
      <Router>
        <QueryClientProvider client={queryClient}>
          <AppChakraProvider>
            <EmbeddedAuthProvider>
              <EmbeddedAuthGuard>
                <MetricsIntervalContextProvider>{routes}</MetricsIntervalContextProvider>
              </EmbeddedAuthGuard>
            </EmbeddedAuthProvider>
          </AppChakraProvider>
        </QueryClientProvider>
      </Router>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppChakraProvider>
        <Router>
          <AuthProvider>
            <AuthGuardPage>
              <DialogProvider>
                <MetricsIntervalContextProvider>{routes}</MetricsIntervalContextProvider>
              </DialogProvider>
            </AuthGuardPage>
          </AuthProvider>
        </Router>
      </AppChakraProvider>
      <HubSpotBox />
    </QueryClientProvider>
  );
};

export default App;

export enum DeviceType {
  ENVIROQ = "ENVIROQ",
  THERMALQ = "THERMALQ",
  HOTDROP = "HOTDROP",
  AIRWITS = "AIRWITS",
  AIRWITS_IAQ = "AIRWITS_IAQ",
  AIRWITS_CO2 = "AIRWITS_CO2",
  AIRWITS_CO2_PLUS = "AIRWITS_CO2_PLUS",
  AIRWITS_PM = "AIRWITS_PM",
  DETECTIFY = "DETECTIFY",
  ADEUNIS_PULSE_WATER = "ADEUNIS_PULSE_WATER",
  ADEUNIS_PULSE = "ADEUNIS_PULSE",
  FLEXSENSE = "FLEXSENSE",
  FLEXSENSE_PROBE = "FLEXSENSE_PROBE",
  VOLTDROP = "VOLTDROP",
  VOLTDROP_PHASE = "VOLTDROP_PHASE",
  ROOM_RADAR = "ROOM_RADAR",
  ROOM_RADAR_LINE = "ROOM_RADAR_LINE",
  ROOM_RADAR_REGION = "ROOM_RADAR_REGION",
  GATEWAY = "GATEWAY",
  MOKO_LW005 = "MOKO_LW005",
  EMS2DESK = "EMS2DESK",
  ERS2EYE = "ERS2EYE",
  MILESIGHT_CT = "MILESIGHT_CT",
  MILESIGHT_EM300 = "MILESIGHT_EM300",
  MILESIGHT_AM103 = "MILESIGHT_AM103",
  MILESIGHT_WS202 = "MILESIGHT_WS202",
  MILESIGHT_WS523 = "MILESIGHT_WS523",
  UNKNOWN = "UNKNOWN",
}

export const ACCEPTED_DEVICE_TYPES = [
  DeviceType.ENVIROQ,
  DeviceType.THERMALQ,
  DeviceType.AIRWITS,
  DeviceType.AIRWITS_IAQ,
  DeviceType.AIRWITS_CO2,
  DeviceType.AIRWITS_CO2_PLUS,
  DeviceType.AIRWITS_PM,
  DeviceType.DETECTIFY,
  DeviceType.HOTDROP,
  DeviceType.GATEWAY,
  DeviceType.FLEXSENSE,
  DeviceType.FLEXSENSE_PROBE,
  DeviceType.VOLTDROP,
  DeviceType.ROOM_RADAR,
  DeviceType.ROOM_RADAR_LINE,
  DeviceType.ROOM_RADAR_REGION,
  DeviceType.ADEUNIS_PULSE,
  DeviceType.ADEUNIS_PULSE_WATER,
  DeviceType.EMS2DESK,
  DeviceType.ERS2EYE,
  DeviceType.MILESIGHT_CT,
  DeviceType.MILESIGHT_EM300,
  DeviceType.MILESIGHT_AM103,
  DeviceType.MILESIGHT_WS202,
  DeviceType.MILESIGHT_WS523,
  DeviceType.MOKO_LW005,
];

import { deletePersistentTokens, persistAuthToken } from "../auth/authPersist";
import { baseURL, appClientDetails, NotFoundError, RefreshResponse } from "./api-types";
import { DeviceAssignment } from "../types/DeviceAssignment";
import { GenericDevice } from "../types/GenericDevice";
import { Device } from "../types/Device";
import { HotDrop } from "../types/HotDrop";
import { DeviceSignal } from "../types/DeviceSignal";
import { Sample } from "../types/Sample";
import { InstallationLocation } from "../types/InstallationLocation";
import { ReadingSet } from "../types/ReadingSet";
import { isArray } from "highcharts";
import dayjs from "@tether-web-portal/dayjs-setup";
import { PORTAL_USER_DATA_STORAGE_KEY } from "lib/auth/AuthProvider";
import * as Sentry from "@sentry/react";

const AUTH_TOKEN_URL = `${baseURL}/oauth/token`;
let refreshPromise: Promise<RefreshResponse> | null = null;

interface QueryParams {
  [key: string]: string | string[];
}

const getQueryStringFromParams = (query: QueryParams) => {
  return Object.keys(query).reduce((acc, curr) => {
    const currVal = query[curr];
    let queryString = "";
    if (isArray(currVal)) {
      queryString = (currVal as string[]).map((val) => `${curr}=${val}`).join("&");
    } else {
      queryString = `${curr}=${currVal}`;
    }
    return query[curr] ? `${acc}${acc ? "&" : ""}${queryString}` : acc;
  }, "");
};

export default class APIService {
  public token: string | null = null;
  public scope: string | null = null;
  public refreshToken: string | null = null;
  private accessTokenExpiry: number = dayjs().valueOf() - 1000; // set it to in the past so it will be refreshed on load.
  private isRefreshingToken: boolean = false;

  setRefreshToken(refreshToken: string | null) {
    this.refreshToken = refreshToken;
  }
  setToken(token: string | null) {
    this.token = token;
  }

  setScope(scope: string | null) {
    this.scope = scope;
  }

  /**
   * If there is a refresh token, then attempt to refresh
   */
  async attemptToRefreshToken(refreshToken: string | null, _scope: string | null): Promise<RefreshResponse> {
    this.isRefreshingToken = true;

    if (!this.refreshToken) {
      throw new Error("No Refresh token");
    }

    if (!this.scope) {
      throw new Error("No scope on API");
    }

    const refreshRequestBody = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: _scope || undefined,
      ...appClientDetails,
    };

    const refreshResponse = await fetch(AUTH_TOKEN_URL, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(refreshRequestBody),
    });

    try {
      const {
        access_token: token,
        refresh_token: newRefreshToken,
        expires_in: maxAge,
        scope,
      } = await refreshResponse.json();

      if (refreshResponse.status !== 200) {
        deletePersistentTokens();
        localStorage.removeItem(PORTAL_USER_DATA_STORAGE_KEY);
        window.location.reload(); //preserve page the user was on when logging back in
        return {
          token: '',
          refreshToken: '',
          accessTokenExpiry: 0,
          scope: '',
        };
      }
      // if it gets here then it was successful
      this.setToken(token);
      this.setRefreshToken(newRefreshToken);
      this.setScope(scope);

      this.accessTokenExpiry = dayjs().valueOf() + (maxAge / 2) * 1000;

      persistAuthToken({
        token,
        refreshToken: newRefreshToken,
        maxAge,
        scope,
      });

      this.isRefreshingToken = false;

      return {
        token,
        refreshToken: newRefreshToken,
        accessTokenExpiry: this.accessTokenExpiry,
        scope,
      };
    } catch (error) {
      Sentry.captureException(error);
      this.isRefreshingToken = false;
      throw error;
    }
  }

  defaultHeaders = () => {
    const headers: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.token) {
      headers["Authorization"] = `Bearer ${this.token!}`;
    }
    return headers;
  };

  makeRequest = async (
    url: string,
    method: "DELETE" | "GET" | "PUT" | "POST",
    body?: any,
    query?: QueryParams,
    contentType?: string
  ) => {
    try {
      const reqOptions: any = {
        headers: this.defaultHeaders(),
        credentials: "same-origin", // for westpac proxy
        method,
      };

      reqOptions.headers["Accept"] = "application/json";
      reqOptions.headers["Content-Type"] = contentType || "application/json";

      if (this.token) {
        // check if token needs to be refreshed
        if (!this.isRefreshingToken && dayjs().valueOf() > this.accessTokenExpiry) {
          try {
            if (!refreshPromise) {
              refreshPromise = this.attemptToRefreshToken(this.refreshToken, this.scope);
            }

            await refreshPromise;
            refreshPromise = null;
          } catch (error) {
            refreshPromise = null;
            console.log("ERROR REFRESHING TOKEN", error);
          }
        }
        reqOptions.headers["Authorization"] = `Bearer ${this.token!}`;
      }

      if (body) {
        reqOptions.body = body;
      }

      if (url[0] === "/") {
        url = url.substring(1);
      }

      let fullUrl = url.includes("http") ? url : `${baseURL}/${url}`;

      const queryString = query && getQueryStringFromParams(query);

      if (queryString) {
        fullUrl += `?${queryString}`;
      }

      let response = await fetch(fullUrl, reqOptions);

      if (response.status === 401 && this.refreshToken && url !== AUTH_TOKEN_URL) {
        if (!refreshPromise) {
          console.log("401 found attemptToRefreshToken makeJSONRequest");
          refreshPromise = this.attemptToRefreshToken(this.refreshToken, this.scope);
        }

        await refreshPromise;
        refreshPromise = null;

        // try again
        response = await fetch(fullUrl, {
          ...reqOptions,
          headers: {
            ...reqOptions.headers,
          },
        });
      }

      if (response.status === 404) {
        const responseJSON = await response.json();
        throw new NotFoundError(responseJSON.message);
      }

      if (response.status === 204) {
        return null;
      }

      // returning response data
      if (response.ok) {
        return response.json();
      }

      // returning error message
      const responseJSON = await response.json();
      throw new Error(responseJSON.message || responseJSON.error_description);
    } catch (error) {
      refreshPromise = null;
      throw error;
    }
  };

  makeJSONRequest = async (
    url: string,
    method: "DELETE" | "GET" | "PUT" | "POST",
    body?: any,
    query?: QueryParams
  ) => {
    return this.makeRequest(url, method, JSON.stringify(body), query, "application/json");
  };

  del = (url: string, data?: any, query?: QueryParams) => this.makeJSONRequest(url, "DELETE", data, query);
  get = (url: string, query?: QueryParams) => this.makeJSONRequest(url, "GET", undefined, query);
  put = (url: string, data: any, query?: QueryParams) => this.makeJSONRequest(url, "PUT", data, query);
  post = (url: string, data?: any) => this.makeJSONRequest(url, "POST", data);
  putData = (url: string, data: any, contentType: string, query?: QueryParams) =>
    this.makeRequest(url, "PUT", data, query, contentType);

  async locationDevices(installationLocationId: string): Promise<GenericDevice[]> {
    const assignments: DeviceAssignment[] = await this.makeJSONRequest(
      `v2/deviceassignment?installationLocationId=${installationLocationId}`,
      "GET"
    );
    const promises = assignments.map(async (assignment) => {
      try {
        return new Device(await this.makeJSONRequest(`v2/device/${assignment.deviceId}`, "GET"));
      } catch (e) {
        if (e instanceof Error && e.message === "Not Found") {
          return new HotDrop(await this.makeJSONRequest(`v2/hotdrop/${assignment.deviceId}`, "GET"));
        }
      }
    });
    return (await Promise.all(promises)).filter((d) => d !== undefined) as GenericDevice[];
  }

  async installationLocations(installationId: string): Promise<InstallationLocation[]> {
    return this.makeJSONRequest(`v2/installationlocation?installationId=${installationId}`, "GET");
  }
  async installationLocation(installationLocationId: string): Promise<InstallationLocation> {
    return this.makeJSONRequest(`v2/installationlocation/${installationLocationId}`, "GET");
  }
  async latestSample(installationLocationId: string): Promise<Sample> {
    return this.makeJSONRequest(`v2/installationlocation/${installationLocationId}/samples/latest`, "GET");
  }
  async deviceSignal(deviceId: string): Promise<DeviceSignal | null> {
    const arrayResult = await this.makeJSONRequest(`v2/device/${deviceId}/signal?limit=1`, "GET");
    if (arrayResult.length === 0) {
      return null;
    }

    return arrayResult[0];
  }
  async installationLocationReadings(
    installationLocationId: string,
    fromTimestamp: string,
    toTimestamp: string
  ): Promise<ReadingSet> {
    return this.makeJSONRequest(`v2/installationlocation/${installationLocationId}/readings`, "GET", null, {
      fromTimestamp,
      toTimestamp,
    });
  }
}
